body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  
  }
  .mainDIV{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  
  }

  .button-27 {
    -webkit-appearance: none;
            appearance: none;
    background-color: #000000;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 40px;
    max-width: 200px;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
    position: fixed;
    bottom: 1.5rem; /* Adjust the distance from the bottom as needed */
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
 
 
  }


  

  
.center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
}
.wave {
  width: 3px;
  height: 60px;
  background: linear-gradient(45deg, rgb(0, 0, 0), #a2a2a2);
  margin: 10px;
  -webkit-animation: wave 1s linear infinite;
          animation: wave 1s linear infinite;
  border-radius: 20px;
}
.wave:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.wave:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.wave:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.wave:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.wave:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.wave:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.wave:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.wave:nth-child(9) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.wave:nth-child(10) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
/* HTML: <div class="loader"></div> */
.loader {
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    -webkit-animation: l5 1s infinite linear alternate;
            animation: l5 1s infinite linear alternate;
  }
  @-webkit-keyframes l5 {
      0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
      33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
      66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
      100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
  }
  @keyframes l5 {
      0%  {box-shadow: 20px 0 #000, -20px 0 #0002;background: #000 }
      33% {box-shadow: 20px 0 #000, -20px 0 #0002;background: #0002}
      66% {box-shadow: 20px 0 #0002,-20px 0 #000; background: #0002}
      100%{box-shadow: 20px 0 #0002,-20px 0 #000; background: #000 }
  }
